import React, { useEffect, useState } from "react";
import SellYourWavAdvertise from "./SellYourWavAdvertise";
import SellYourWavHeader from "./SellYourWavHeader";
import SellYourWavPricing from "./SellYourWavPricing";
import "./style.css";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
const SellYourWav = () => {
  const [singlePackage, setSinglePackage] = useState(null);
  const getPackages = async () => {
    try {
      const response = await fetch(`${url}/price/get`);
      if (response.ok) {
        const data = await response.json();
        setSinglePackage(data.data.price[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getPackages();
  }, []);
  return (
    <>
      <div className="coming-page">

        <h4 >Our sales options are currently being re-structured, please email <a style={{ color: '#000' }} href="mailto:hello@wavcompare.com">hello@wavcompare.com</a> for more information.</h4>
      </div>
      {/* <div id="sell-your-wav">
        <SellYourWavHeader />
        <SellYourWavAdvertise />
        {singlePackage && (
          <SellYourWavPricing
            singlePackage={singlePackage}
            setSinglePackage={setSinglePackage}
            url={url}
          />
        )}

      </div> */}

    </>
  );
};

export default SellYourWav;
